import React, {createRef} from "react";
import {
    AmbientLight,
    PerspectiveCamera,
    Scene,
    WebGLRenderer
} from "three";
import {VRButton} from "three/examples/jsm/webxr/VRButton.js";
import {
    ThemeProvider,
    buildComponents,
    PanelRoot
} from "webgl-components";
import {attachMetaQuestKeyboard, InteractionHandlerImpl, KeyboardHolder} from "webgl-components";
import {InteractionHandler} from "webgl-components/src/inputs/InteractionHandler";

interface CanvasProps {
    uiDefinition: string;
    onDataUpdate: (newData: string) => void;
}

// PureComponents are only re-rendered if the props change
export default class Canvas extends React.PureComponent<CanvasProps> {
    ref = createRef<HTMLCanvasElement>()

    init = false;
    private interactionHandler!: InteractionHandler;
    private keyboardHolder!: KeyboardHolder;
    private themeProvider!: ThemeProvider;
    private scene!: Scene;
    private genUiPanel: PanelRoot | undefined = undefined;

    render() {
        return <canvas
            ref={this.ref}
        />;
    }

    resize(renderer: WebGLRenderer) {
        const canvasW = renderer.domElement.getBoundingClientRect().width;
        const canvasH = renderer.domElement.getBoundingClientRect().height;

        renderer.setSize(canvasW, canvasH, false)
    }

    componentDidMount() {
        const canvas = this.ref.current;
        if (canvas == null) {
            throw Error("unable to get canvas")
        }
        if (this.init) {
            return;
        }
        this.init = true;

        this.scene = new Scene();
        const renderer = new WebGLRenderer({canvas, antialias: true});

        this.resize(renderer);

        const buttonHost = document.getElementById("Button-host");
        if (buttonHost) {
            const button = VRButton.createButton(renderer);
            button.style.position = "static";
            button.style.display = "inline-block";
            button.style.margin = "5px";

            buttonHost.innerHTML = "";
            buttonHost.appendChild(button);
        }

        const camera = new PerspectiveCamera(45, canvas.clientWidth / canvas.clientHeight, 0.1, 100);
        camera.position.set(0, 2, -1)

        window.addEventListener("resize", () => {
            const canvas = this.ref.current;
            if (canvas != null) {
                renderer.setPixelRatio(window.devicePixelRatio);
                this.resize(renderer);
                camera.aspect = canvas.clientWidth / canvas.clientHeight;
                camera.updateProjectionMatrix();
            }
        })

        this.themeProvider = new ThemeProvider(true);
        this.interactionHandler = new InteractionHandlerImpl(this.scene, renderer, camera)
        this.keyboardHolder = attachMetaQuestKeyboard(new KeyboardHolder(), renderer.xr);
        this.keyboardHolder.getKeyboard()?.focus();

        this.scene.add(new AmbientLight(0xffffff, 0.5));

        renderer.setAnimationLoop(() => {
            this.interactionHandler.update()
            renderer.render(this.scene, camera);
        });

        // Not automatic for first iteration
        this.componentDidUpdate()
    }

    componentDidUpdate() {
        if (this.genUiPanel) {
            this.genUiPanel.removeFromParent();
            this.interactionHandler.clear();
        }

        this.genUiPanel = buildComponents(JSON.parse(this.props.uiDefinition), {
            interactionHandler: this.interactionHandler,
            keyboardHolder: this.keyboardHolder,
            themeProvider: this.themeProvider
        });

        this.genUiPanel.position.set(-4, 0, -8)
        this.scene.add(this.genUiPanel);

        this.genUiPanel.addEventListener("stateChanged", (e: any) => {
            this.props.onDataUpdate(JSON.stringify(e.state, null, 2))
        })

        this.props.onDataUpdate(JSON.stringify(this.genUiPanel.userData.values, null, 2))
    }
}