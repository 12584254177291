export function interpolateValueAt(uv) {
    // copies logic from the shader to point at the right value
    const radius = 0.4;
    const centerY = 0.5 - radius / 4.0;
    const downAngle = Math.PI / 3.0 - Math.PI / 2.0;
    let actualAngle = Math.atan2(uv.y - centerY, uv.x - 0.5);
    // down left quadrant should be positive (for better comparison purpose)
    if (actualAngle < -Math.PI / 2.) {
        actualAngle += 2.0 * Math.PI;
    }
    // reverse of shader's valueAngle
    return (actualAngle - downAngle) / (4. * Math.PI / 3.0);
}
