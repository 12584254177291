export var Kind;
(function (Kind) {
    Kind["Action"] = "action";
    Kind["Color"] = "color";
    Kind["Float"] = "float";
    Kind["Ghost"] = "ghost";
    Kind["Gradient"] = "gradient";
    Kind["Int"] = "int";
    Kind["Nest"] = "nest";
    Kind["Progress"] = "progress";
    Kind["Select"] = "select";
    Kind["Text"] = "text";
    Kind["Toggle"] = "toggle";
    Kind["Xy"] = "xy";
    Kind["Zoom"] = "zoom";
})(Kind || (Kind = {}));
