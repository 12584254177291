import { Group, } from "three";
export class Panel extends Group {
    settings;
    panelRoot;
    skipAt = [];
    subPanels = [];
    constructor(settings, panelRoot) {
        super();
        this.settings = settings;
        this.panelRoot = panelRoot;
        this.visible = false;
        this.userData.values = {};
        this.panelRoot.add(this);
    }
    skip(elements = 1) {
        for (let i = 0; i < elements; i++) {
            this.skipAt.push(this.children.length);
        }
    }
    addSubPanel(panel) {
        this.subPanels.push(panel);
    }
    hide() {
        this.visible = false;
        this.subPanels.forEach((panel) => panel.hide());
    }
    positionChildren() {
        let pos1 = -1;
        let pos2 = 0;
        const positionVertically = this.settings.maxInColumn !== -1;
        const maxPerRow = positionVertically ? this.settings.maxInColumn : this.settings.maxInRow;
        let skipIndex = 0;
        for (let i = 0; i < this.children.length; i++) {
            pos1 += 1;
            if (pos1 >= maxPerRow) {
                pos1 = 0;
                pos2 += 1;
            }
            if (positionVertically) {
                this.children[i].position.set(pos2, pos1, 0);
            }
            else {
                this.children[i].position.set(pos1, pos2, 0);
            }
            // Skip items
            while (skipIndex < this.skipAt.length && this.skipAt[skipIndex] === i) {
                skipIndex += 1;
                pos1 += 1;
                if (pos1 >= maxPerRow) {
                    pos1 = 0;
                    pos2 += 1;
                }
            }
        }
    }
    toggle(relativeTo) {
        this.setVisibility(!this.visible, relativeTo);
    }
    setVisibility(visible, relativeTo) {
        this.visible = visible;
        if (!visible) {
            this.subPanels.forEach((panel) => panel.setVisibility(false));
        }
        let x = relativeTo?.position.x || 0;
        while (relativeTo?.parent && relativeTo?.parent !== this.panelRoot) {
            x += relativeTo?.parent.position.x;
            relativeTo = relativeTo?.parent;
        }
        this.panelRoot.reposition(this, x);
    }
    get width() {
        return this.settings.maxInRow === -1
            ? Math.ceil((this.children.length + this.skipAt.length) / this.settings.maxInColumn)
            : this.settings.maxInRow;
    }
    get height() {
        return this.settings.maxInColumn === -1
            ? Math.ceil((this.children.length + this.skipAt.length) / this.settings.maxInRow)
            : this.settings.maxInColumn;
    }
}
/**
 * Root of the generated components. Handles the layout of the sub panels.
 *
 * @fires stateChanged When the state of an element changes.
 */
export class PanelRoot extends Group {
    // Each array is a column, containing stacked panels and their upper height
    columns = [];
    above = {};
    insert(updated, left) {
        const end = left + updated.width;
        while (this.columns.length < end) {
            this.columns.push([]);
        }
        // Same marker so update is straightforward
        const marker = { panel: updated, topHeight: updated.height };
        // Find the height to place the panel at
        let height = 0;
        for (let i = left; i < end; i++) {
            const column = this.columns[i];
            if (column.length > 0) {
                const last = column[column.length - 1];
                height = Math.max(height, last.topHeight);
                if (!this.above[last.panel.id].includes(marker)) {
                    this.above[last.panel.id].push(marker);
                }
            }
        }
        marker.topHeight += height;
        // Add the panel to the columns
        for (let i = left; i < end; i++) {
            this.columns[i].push(marker);
        }
        // Set the position
        updated.position.set(left, height, 0);
        this.above[updated.id] = [];
    }
    removePanel(panel) {
        // Clear all entries in the columns
        for (let i = 0; i < this.columns.length; i++) {
            const column = this.columns[i];
            for (let j = 0; j < column.length; j++) {
                if (column[j].panel === panel) {
                    column.splice(j, 1);
                    j--;
                }
            }
        }
        this.above[panel.id].forEach(p => {
            this.moveDown(p);
        });
        delete this.above[panel.id];
    }
    moveDown(marker) {
        const panel = marker.panel;
        const left = panel.position.x;
        const right = left + panel.width;
        // Check lowest below
        let lowest = 0;
        for (let i = left; i < right; i++) {
            const column = this.columns[i];
            const lastIndex = column.findIndex(m => m === marker);
            if (lastIndex > 0) {
                lowest = Math.max(lowest, column[lastIndex - 1].topHeight);
            }
        }
        if (lowest < panel.position.y) {
            panel.position.y = lowest;
            marker.topHeight = lowest + panel.height;
            if (this.above[panel.id]) {
                this.above[panel.id].forEach(p => {
                    this.moveDown(p);
                });
            }
        }
    }
    reposition(updated, at) {
        if (this.above[updated.id]) {
            this.removePanel(updated);
        }
        if (updated.visible) {
            this.insert(updated, Math.floor(at));
        }
    }
}
