import UiComponent from "../../UiComponent";
import vertexShader from "./vertex.glsl";
import fragmentShader from "./fragment.glsl";
import { ShaderMaterial, Vector2 } from "three";
const valueShaderMaterial = new ShaderMaterial({
    vertexShader, fragmentShader,
    uniforms: {
        cursor: { value: new Vector2() },
    },
});
export default class XyControl extends UiComponent {
    xRange;
    yRange;
    valueText;
    min;
    range;
    step;
    constructor(description, xRange, yRange) {
        super(new Vector2(xRange.current || 0, yRange.current || 0), description, valueShaderMaterial.clone());
        this.xRange = xRange;
        this.yRange = yRange;
        this.min = new Vector2(xRange.min || 0, yRange.min || 0);
        this.range = new Vector2(xRange.max || 1, yRange.max || 1).sub(this.min);
        this.step = new Vector2(xRange.step || 0.01, yRange.step || 0.01);
        this.valueText = this.addText(`(${this.xRange.current}, ${this.yRange.current})`, this._state.x, this._state.y);
        this.valueText.fontSize = 0.1;
        // Update
        this.state = this._state;
    }
    get state() {
        return this._state;
    }
    set state(value) {
        // round is used as it is closer to user cursor
        value = value.sub(this.min).divide(this.step).round().multiply(this.step).add(this.min);
        this._state = value;
        this.dispatchEvent({ type: 'stateChanged', state: value, target: this });
        const material = this.material;
        this.valueText.text = `(${this._state.x}, ${this._state.y})`;
        const visualValue = value.clone().sub(this.min).divide(this.range).sub(new Vector2(.5, .5));
        this.valueText.position.set(visualValue.x + .15, visualValue.y - .2, 0);
        material.uniforms.cursor.value = visualValue;
    }
    onSelectStart(event) {
        const uv = event.intersection.uv;
        if (uv) {
            this.state = uv.multiply(this.range).add(this.min);
        }
    }
    onSelectMove(event) {
        this.onSelectStart(event);
    }
}
