import { Mesh, MeshBasicMaterial, PlaneGeometry } from "three";
import { Text } from "troika-three-text";
export const DEFAULT_MATERIAL = new MeshBasicMaterial({ color: 0x000000 });
export const DEFAULT_GEOMETRY = new PlaneGeometry();
/**
 * Base class for most UI components of this library. Provides a common text description, geometry and state interface.
 */
export default class UiComponent extends Mesh {
    _state;
    nameText;
    intersectedObjects;
    constructor(_state, description, material = DEFAULT_MATERIAL, geometry = DEFAULT_GEOMETRY) {
        super(geometry, material);
        this._state = _state;
        this.nameText = this.addText(description, 0, -0.5);
        this.nameText.fontSize *= .5;
        this.nameText.color = 0x777777;
        this.intersectedObjects = [this];
    }
    set description(name) {
        this.nameText.text = name;
    }
    get description() {
        return this.nameText.text;
    }
    addText(initialContent, x, y) {
        const text = createText(initialContent, x, y);
        this.add(text);
        return text;
    }
    dispose() {
        this.geometry.dispose();
    }
}
/**
 * Create a text mesh with default settings (centered, top baseline, white, 0.2 font size)
 * @param initialContent text
 * @param x x position (centered on this position)
 * @param y y position (top baseline on this position)
 */
export function createText(initialContent, x, y) {
    const text = new Text();
    text.text = initialContent;
    text.fontSize = 0.2;
    text.position.set(x, y, 0.01);
    text.anchorX = 'center';
    text.anchorY = 'top-baseline';
    text.color = 0xFFFFFF;
    text.sync();
    return text;
}
