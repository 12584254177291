import { Vector2, Vector3 } from "three";
const sqrtOf3DividedBy3 = Math.sqrt(3.0) / 3.0;
const hexSize = 2;
const center = new Vector2(0.5, 0.5);
/**
 * Retrieve the displayed color on the shader.
 *
 * Should be updated if the shader gets updated
 * @param uv uv intersection on the plane
 * @param z z rotation of the controller (or whichever pickDistance was provided to the shader)
 */
export function getDisplayedColor(uv, z) {
    const point = uv.sub(center).multiplyScalar(hexSize);
    // convert vUv 2d coordinates to 3d cube coordinates (see http://www.redblobgames.com/grids/hexagons)
    const coords = new Vector3(sqrtOf3DividedBy3 * point.x - 1.0 / 3.0 * point.y, 2.0 / 3.0 * point.y, 0.0);
    coords.z = -coords.x - coords.y;
    z += .25;
    const result = coords.add(new Vector3(z, z, z));
    result.x = Math.max(0, Math.min(1, result.x));
    result.y = Math.max(0, Math.min(1, result.y));
    result.z = Math.max(0, Math.min(1, result.z));
    return result;
}
