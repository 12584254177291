import { Line, Matrix4, Raycaster, Vector3 } from "three";
import { XRControllerModelFactory } from "three/examples/jsm/webxr/XRControllerModelFactory.js";
import { BufferGeometry } from "three/src/core/BufferGeometry.js";
/**
 * Handles controller events and dispatches them to the registered `ControllerEventHandler`.
 *
 * Needs `update` to be called every frame for intersection detection.
 *
 * Supports mouse events or xr controllers.
 */
export default class InteractionHandlerImpl {
    renderer;
    camera;
    controllers;
    intersectedObjects = [];
    objectToController = new Map();
    rayCaster = new Raycaster();
    mouseIntersectedObject = null;
    mousePos = { x: 0, y: 0 };
    constructor(scene, renderer, camera) {
        this.renderer = renderer;
        this.camera = camera;
        renderer.xr.enabled = true;
        this.controllers = this.buildControllers(scene);
        this.controllers.forEach(controller => {
            controller.addEventListener('selectstart', () => {
                const intersection = this.intersectWith(controller);
                if (intersection) {
                    controller.userData.selectedObject = this.selectStart(intersection, controller.userData.selectedObject, controller);
                }
            });
            controller.addEventListener('selectend', () => {
                controller.userData.selectedObject = this.selectEnd(controller.userData.selectedObject, controller);
            });
        });
        renderer.domElement.addEventListener('mousedown', () => {
            const intersection = this.intersectWithMouse();
            if (intersection) {
                this.mouseIntersectedObject = this.selectStart(intersection, this.mouseIntersectedObject, null);
            }
        });
        renderer.domElement.addEventListener('mouseup', () => {
            const intersection = this.intersectWithMouse();
            if (intersection) {
                this.mouseIntersectedObject = this.selectEnd(this.mouseIntersectedObject, null);
            }
        });
        // then, mousemove
        renderer.domElement.addEventListener('mousemove', (event) => {
            const bounds = renderer.domElement.getBoundingClientRect();
            const x = 2 * (event.clientX - bounds.left) / bounds.width - 1;
            const y = 1 - 2 * (event.clientY - bounds.top) / bounds.height;
            this.mousePos = { x, y };
            if (this.mouseIntersectedObject) {
                const intersection = this.intersectWithMouse();
                if (intersection?.object === this.mouseIntersectedObject) {
                    this.selectMove(intersection, null);
                }
            }
        });
    }
    selectStart(intersection, currentObject, controller) {
        if (currentObject !== intersection.object) {
            this.selectEnd(currentObject, controller);
        }
        this.objectToController.get(intersection.object)?.onSelectStart?.({
            type: "selectstart",
            controller,
            intersection
        });
        return intersection.object;
    }
    selectMove(intersection, controller) {
        this.objectToController.get(intersection.object)?.onSelectMove?.({ type: "selectmove", controller, intersection });
    }
    selectEnd(object, controller) {
        if (object) {
            this.objectToController.get(object)?.onSelectEnd?.({ type: "selectend", controller, });
        }
        return null;
    }
    intersectWithMouse() {
        this.rayCaster.setFromCamera(this.mousePos, this.camera);
        return this.intersect();
    }
    intersectWith(controller) {
        const rotationMatrix = new Matrix4();
        rotationMatrix.extractRotation(controller.matrixWorld);
        this.rayCaster.ray.origin.setFromMatrixPosition(controller.matrixWorld);
        this.rayCaster.ray.direction.set(0, 0, -1).applyMatrix4(rotationMatrix);
        return this.intersect();
    }
    intersect() {
        // Filter hidden objects: they are probably mis-placed and should not be interacted with
        const intersects = this.rayCaster.intersectObjects(this.intersectedObjects.filter(isVisibleRecursively));
        if (intersects.length > 0) {
            return intersects[0];
        }
    }
    update() {
        if (this.renderer.xr.isPresenting) {
            this.controllers.forEach(controller => {
                if (controller.userData.selectedObject) {
                    const intersection = this.intersectWith(controller);
                    if (intersection) {
                        this.selectMove(intersection, controller);
                    }
                }
            });
        }
        else {
            if (this.mouseIntersectedObject) {
                const intersection = this.intersectWithMouse();
                if (intersection) {
                    this.selectMove(intersection, null);
                }
            }
        }
    }
    register(...handlers) {
        handlers.forEach(handler => {
            if (handler.intersectedObjects instanceof Array) {
                handler.intersectedObjects.forEach(object => {
                    this.objectToController.set(object, handler);
                    this.intersectedObjects.push(object);
                });
            }
            else {
                this.objectToController.set(handler.intersectedObjects, handler);
                this.intersectedObjects.push(handler.intersectedObjects);
            }
        });
    }
    buildControllers(scene) {
        const controllerModelFactory = new XRControllerModelFactory();
        const geometry = new BufferGeometry().setFromPoints([
            new Vector3(0, 0, 0),
            new Vector3(0, 0, -1)
        ]);
        const line = new Line(geometry);
        line.scale.z = 10;
        const controllers = [];
        for (let i = 0; i < 2; i++) {
            const controller = this.renderer.xr.getController(i);
            controller.add(line.clone());
            controller.userData.selectPressed = false;
            controller.userData.selectPressedPrev = false;
            scene.add(controller);
            controllers.push(controller);
            const grip = this.renderer.xr.getControllerGrip(i);
            grip.add(controllerModelFactory.createControllerModel(grip));
            scene.add(grip);
        }
        return controllers;
    }
    clear() {
        this.objectToController.clear();
        this.intersectedObjects = [];
    }
}
function isVisibleRecursively(object) {
    return object.visible && (!object.parent || isVisibleRecursively(object.parent));
}
