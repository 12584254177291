import UiComponent from "../../UiComponent";
import { MeshBasicMaterial, ShaderMaterial, TextureLoader, Vector3 } from "three";
import { toColor } from "../../GenUi";
import vertexShader from "./vertex.glsl";
import fragmentShader from "./fragment.glsl";
const shaderMaterial = new ShaderMaterial({
    vertexShader, fragmentShader,
    uniforms: {
        color: { value: new Vector3(1, 0, .5) },
    },
    transparent: true
});
export default class ButtonControl extends UiComponent {
    callback;
    icons = {};
    constructor(description, callback, settings, themeProvider) {
        super(callback, description);
        this.callback = callback;
        const face = settings ? settings.face : null;
        switch (face) {
            case "icon":
                if (!settings.icons || settings.icons.length === 0) {
                    throw new Error("No icon provided for button");
                }
                const textureLoader = new TextureLoader();
                settings.icons.forEach((icon) => {
                    if (!icon.url) {
                        throw new Error("No url provided for icon");
                    }
                    textureLoader.load(icon.url.value, (texture) => {
                        this.icons[icon.theme] = texture;
                        if (themeProvider.theme === icon.theme) {
                            this.material = new MeshBasicMaterial({ map: texture, transparent: true });
                        }
                    });
                });
                themeProvider.addEventListener('themeChanged', (event) => {
                    const material = this.material;
                    if (this.icons[event.theme] && material instanceof MeshBasicMaterial) {
                        material.map = this.icons[event.theme];
                        material.needsUpdate = true;
                    }
                });
                break;
            case "color":
                const material = shaderMaterial.clone();
                const color = toColor(settings.color);
                material.uniforms.color.value = new Vector3(color.r, color.g, color.b);
                this.material = material;
                break;
            case "title":
                this.intersectedObjects = [this, this.addText(description, 0, 0)];
                break;
            case "empty":
                break;
            case "expand":
            case "focus":
            default:
                this.intersectedObjects = [this, this.addText(`<${description}>`, 0, -.2)];
                console.error("action face " + face + " not implemented");
                break;
        }
        // TODO rendering
    }
    onSelectStart() {
        this.state();
    }
    get state() {
        return this._state;
    }
    set state(value) {
        this._state = value;
        this.dispatchEvent({ type: 'stateChanged', state: value, target: this });
    }
}
