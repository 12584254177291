/**
 * Holds an instance of the `KeyboardInstance` to use for handling keyboard input.
 *
 * Since internal instance can change, this holder should be used on every "input session".
 *
 * ```typescript
 * const keyboardHolder = new KeyboardHolder();
 * attachMetaQuestKeyboard(keyboardHandler, renderer.xr);
 *
 * // when in need for user input
 * const keyboard = keyboardHolder.getKeyboard();
 * if (keyboard) {
 *    keyboard.onchange((value) => {
 *      // do something with value
 *    })
 *    keyboard.onclosed((value) => {
 *      // do something with value
 *    })
 *    keyboard.focus();
 * }
 * ```
 */
export default class KeyboardHolder {
    keyboard;
    setKeyboard(keyboard) {
        this.keyboard = keyboard;
    }
    getKeyboard() {
        return this.keyboard;
    }
    removeKeyboard(keyboard) {
        if (keyboard === undefined || this.keyboard === keyboard) {
            this.keyboard = undefined;
        }
    }
}
