import { EventDispatcher } from "three";
/**
 * Detects if the system is in dark or light mode and holds such value.
 */
export default class ThemeProvider extends EventDispatcher {
    useSystemSettings;
    _theme;
    constructor(useSystemSettings = true) {
        super();
        this.useSystemSettings = useSystemSettings;
        this._theme = "dark";
        // Watch for system settings
        if (window.matchMedia && useSystemSettings) {
            const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');
            this._theme = mediaQueryList.matches ? "dark" : "light";
            mediaQueryList.addEventListener('change', event => {
                this._theme = event.matches ? "dark" : "light";
            });
        }
    }
    get theme() {
        return this._theme;
    }
    set theme(theme) {
        // Disable system settings when manually setting the theme
        this.useSystemSettings = false;
        this._theme = theme;
        this.dispatchEvent({ type: 'themeChanged', theme: theme });
    }
}
