import React, {ChangeEvent, useState} from 'react';
import './App.css';
import Canvas from "./Canvas";
import sampleUiData from './sample.json'
import {validateUiData} from "webgl-components";

function App() {
    const [data, setData] = useState("{}");
    const [ui, setUi] = useState(JSON.stringify(sampleUiData, null, 2));
    const [uiDraft, setUiDraft] = useState(JSON.stringify(sampleUiData, null, 2));
    const [error, setError] = useState<string[] | null>(null);
    const uiChanged = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setUiDraft(event.target.value);

        try {
            const parsedContent = JSON.parse(event.target.value);

            if (!validateUiData(parsedContent)) {
                setError(validateUiData.errors?.map(e => e.message!!) ?? ["Validation failed for unknown reason :("]);
                return;
            } else {
                setUi(event.target.value);
                setError(null);
            }
        } catch (e: any) {
            setError([e.message]);
            return;
        }
    }

    // const dataChanged = (event: ChangeEvent<HTMLTextAreaElement>) => {
    //     setData(event.target.value);
    // };

    return (
        <div className="App">
            <Canvas
                uiDefinition={ui}
                onDataUpdate={setData}
            />
            <div id="Button-host"></div>
            <section className="App-data">
                <label>
                    UI definition
                    <textarea value={uiDraft} onChange={uiChanged}/>
                    {error && <div className="App-error">{error.join(", ")}</div>}
                </label>
                <label>
                    Data
                    <textarea value={data} readOnly={true}/>
                </label>
            </section>
            <footer>
                <p>
                    Note: the <a href="https://developer.oculus.com/documentation/web/webxr-keyboard/">virtual
                    keyboard</a> is
                    currently only supported on Meta Quest<br/>
                    (you may need to enable WebXr Experiments flag in <a href="chrome://flags/">chrome://flags/</a>)
                </p>
            </footer>
        </div>
    );
}

export default App;
