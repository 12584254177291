/**
 * Hook the MetaQuest native keyboard as the default keyboard in XR sessions.
 * @param holder keyboard container
 * @param xrManager XR manager
 *
 * @see https://developer.oculus.com/documentation/web/webxr-keyboard/
 */
export default function attachMetaQuestKeyboard(holder, xrManager) {
    let internalHandler;
    xrManager.addEventListener('sessionstart', () => {
        if (xrManager.getSession().isSystemKeyboardSupported) {
            if (!internalHandler)
                internalHandler = new MetaQuestHandler();
            holder.setKeyboard(internalHandler);
        }
        else {
            console.warn("meta quest keyboard not supported");
        }
    });
    xrManager.addEventListener('sessionend', () => {
        if (internalHandler) {
            holder.removeKeyboard(internalHandler);
            internalHandler.dispose();
        }
        internalHandler = undefined;
    });
    return holder;
}
class MetaQuestHandler {
    myTextField;
    constructor() {
        this.myTextField = document.createElement("input");
        this.myTextField.type = "text";
        document.body.appendChild(this.myTextField);
    }
    focus() {
        this.myTextField.focus();
    }
    onchange(callback) {
        this.myTextField.oninput = () => callback(this.myTextField.value);
    }
    close() {
        this.myTextField.blur();
        this.myTextField.oninput = null;
        return this.myTextField.value;
    }
    onclosed(callback) {
        this.myTextField.onblur = () => callback(this.myTextField.value);
    }
    dispose() {
        this.myTextField.remove();
    }
}
