import { Color, ShaderMaterial, Vector2, Vector3 } from "three";
import UiComponent from "../../UiComponent";
import vertexShader from "./vertex.glsl";
import fragmentShader from "./fragment.glsl";
import { getDisplayedColor } from "./ColorFragmentRetriever";
const shaderMaterial = new ShaderMaterial({
    vertexShader, fragmentShader,
    uniforms: {
        color: { value: new Vector3() },
        picking: { value: false },
        pickPosition: { value: new Vector2(0.5, .5) },
        pickDistance: { value: 0 }
    },
    transparent: true
});
export default class ColorControl extends UiComponent {
    lastValue = { uv: new Vector2(), z: 0 };
    constructor(description) {
        const material = shaderMaterial.clone();
        super(0, description, material);
        const color = new Color(this.state);
        material.uniforms.color.value = new Vector3(color.r, color.g, color.b);
    }
    onSelectStart(event) {
        if (this.material instanceof ShaderMaterial) {
            this.material.uniforms.picking.value = true;
            this.material.uniforms.pickPosition.value = event.intersection.uv;
            this.updatePosition(event);
        }
    }
    onSelectMove(event) {
        this.updatePosition(event);
    }
    updatePosition(event) {
        const uv = event.intersection.uv;
        if (uv && this.material instanceof ShaderMaterial) {
            const controllerRotation = event.controller?.rotation.z || 0;
            this.material.uniforms.pickPosition.value = uv;
            this.material.uniforms.pickDistance.value = controllerRotation;
            this.lastValue = { uv: uv, z: controllerRotation };
        }
    }
    onSelectEnd() {
        if (this.material instanceof ShaderMaterial) {
            const displayedColor = getDisplayedColor(this.lastValue.uv, this.lastValue.z);
            this.material.uniforms.picking.value = false;
            this.state = new Color(displayedColor.x, displayedColor.y, displayedColor.z).getHex();
        }
    }
    get state() {
        return this._state;
    }
    set state(value) {
        if (value !== this._state) {
            this._state = value;
            if (this.material instanceof ShaderMaterial) {
                const color = new Color(value);
                this.material.uniforms.color.value = new Vector3(color.r, color.g, color.b);
            }
            this.dispatchEvent({ type: 'stateChanged', state: value, target: this });
        }
    }
}
