import { ShaderMaterial } from "three";
import UiComponent from "../../UiComponent";
import vertexShader from "./vertex.glsl";
import fragmentShader from "./fragment.glsl";
import { interpolateValueAt } from "./SliderFragmentRetriever";
const shaderMaterial = new ShaderMaterial({
    vertexShader, fragmentShader,
    uniforms: {
        value: { value: 0.5 }
    },
    transparent: true
});
export default class SliderControl extends UiComponent {
    settings;
    valueText;
    constructor(description, settings) {
        const material = shaderMaterial.clone();
        super((settings.min + settings.max) / 2, description, material);
        this.settings = settings;
        this.valueText = this.addText(this.state.toString(), 0, -.17);
        this.intersectedObjects = [this, this.valueText];
        material.uniforms.value.value = 1 - (this._state - settings.min) / (settings.max - settings.min);
    }
    onSelectStart(event) {
        this.updateState(event.intersection.uv);
    }
    onSelectMove(event) {
        this.updateState(event.intersection.uv);
    }
    updateState(uv) {
        let value = interpolateValueAt(uv);
        this.state = (1 - value) * (this.settings.max - this.settings.min) + this.settings.min;
    }
    get state() {
        return this._state;
    }
    set state(value) {
        value = Math.min(this.settings.max, Math.max(this.settings.min, value));
        if (this.settings.step) {
            // force step
            value = Math.round(value / this.settings.step) * this.settings.step;
        }
        this.valueText.text = value.toString();
        if (this.material instanceof ShaderMaterial) {
            this.material.uniforms.value.value = 1 - (value - this.settings.min) / (this.settings.max - this.settings.min);
        }
        this.dispatchEvent({ type: 'stateChanged', state: value, target: this });
        this._state = value;
    }
    dispose() {
        super.dispose();
        this.valueText.dispose();
    }
}
