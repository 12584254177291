import Ajv from 'ajv';
import schema from './data/genui.schema.current.json';
import { Clock, Color } from "three";
import { Kind } from "./data/genui.schema";
import SliderControl from "./components/slider/SliderControl";
import TextControl from "./components/text/TextControl";
import ColorControl from "./components/color/ColorControl";
import ToggleButton from "./components/toggle/ToggleButton";
import ButtonControl from "./components/button/ButtonControl";
import ThemeProvider from "./inputs/Theme";
import XyControl from "./components/xy/XyControl";
import { Panel, PanelRoot } from "./Panel";
import { KeyboardHolder } from "./index";
import attachMetaQuestKeyboard from "./inputs/MetaQuestKeyboard";
import InteractionHandlerImpl from "./inputs/InteractionHandlerImpl";
/**
 * Validate the data structure of the UI using [Ajv validator](https://ajv.js.org/). This can be used before
 * creating the UI to ensure that the data structure is valid and provide a custom handling of the errors.
 */
export const validateUiData = new Ajv().compile(schema);
/**
 * Convert a color defined in the UI definition to a THREE.Color
 * @param color color definition from the UI definition
 * @see GenUiColor
 */
export function toColor(color) {
    if (!color)
        return new Color();
    // TODO transparency not handled
    if (typeof color.r === 'number' && typeof color.g === 'number' && typeof color.b === 'number') {
        return new Color(color.r, color.g, color.b);
    }
    else if (typeof color.hex === 'string') {
        return new Color(color.hex);
    }
    else if (typeof color.h === 'number' && typeof color.s === 'number' && typeof color.l === 'number') {
        return new Color().setHSL(color.h, color.s, color.l);
    }
    return new Color();
}
/**
 * Convert a color defined in the UI definition to a number representation of this color
 * @param color color definition from the UI definition
 */
export function toHexColor(color) {
    return toColor(color).getHex();
}
/**
 * Create a new panel based on the provided UI definition. Validation of the data is done in this function.
 *
 * @param uiDesc description of the UI
 * @param context context initialized (interactions, keyboard), or content to create it (scene, renderer)
 * @returns the root panel of the UI
 * @see validateUiData
 */
export function buildComponents(uiDesc, context) {
    let interactionHandler;
    if (context.interactionHandler) {
        interactionHandler = context.interactionHandler;
    }
    else if (context.renderer && context.camera && context.scene) {
        interactionHandler = new InteractionHandlerImpl(context.scene, context.renderer, context.camera);
    }
    else {
        throw new Error("default interactionHandler, unless provided, requires 'renderer', 'camera' and 'scene' set to be initialized");
    }
    return new UiPanelBuilder(uiDesc, interactionHandler, 
    // provided, with meta quest if renderer, or empty
    context.keyboardHolder || (context.renderer ? attachMetaQuestKeyboard(new KeyboardHolder(), context.renderer.xr) : new KeyboardHolder()), context.themeProvider || new ThemeProvider()).root;
}
// TODO it would be great to make this a builder (not really tied to full JSON data), so users could define UI programmatically
/**
 * Builder for panels using the UI definition. Should be used with the `buildComponents` function.
 */
class UiPanelBuilder {
    interactionHandler;
    keyboardHolder;
    themeProvider;
    panelRoot;
    constructor(uiDesc, interactionHandler, keyboardHolder, themeProvider) {
        this.interactionHandler = interactionHandler;
        this.keyboardHolder = keyboardHolder;
        this.themeProvider = themeProvider;
        this.panelRoot = new PanelRoot();
        if (!validateUiData(uiDesc)) {
            console.error("Error parsing UI data:", validateUiData.errors);
        }
        else if (uiDesc.version !== schema.properties.version.const) {
            console.error("Unsupported UI version:", uiDesc.version, "expected:", schema.properties.version.const);
        }
        else {
            this.withNewPanel({ maxInRow: -1, maxInColumn: 1, distribute: "values" }, (rootPanel) => {
                uiDesc.root?.forEach((root) => this.addComponent(root, rootPanel));
                this.panelRoot.userData.values = rootPanel.userData.values;
            }).toggle();
        }
    }
    get root() {
        return this.panelRoot;
    }
    withNewPanel(settings, builder) {
        const panel = new Panel(settings, this.panelRoot);
        builder(panel);
        panel.positionChildren();
        return panel;
    }
    addComponent(element, parent, addData = true) {
        let control = undefined;
        switch (element.kind) {
            case Kind.Nest:
                const panelDef = element.def.panel;
                // Add panel, and sync data onto parent data
                const panel = this.withNewPanel(panelDef.pages, (panel) => {
                    element.def.children.forEach((child) => this.addComponent(child, panel));
                });
                parent.addSubPanel(panel);
                parent.userData.values[element.name] = panel.userData.values;
                // Add toggle button
                if (panelDef.button) {
                    const button = this.addComponent({
                        kind: Kind.Action,
                        name: element.name || "",
                        def: { face: panelDef.button }
                    }, parent, false);
                    button.state = () => {
                        panel.toggle(button);
                    };
                }
                break;
            case Kind.Float:
            case Kind.Int:
                const range = element.def;
                control = new SliderControl(element.name || "", {
                    min: range.min || 0,
                    max: range.max || 1,
                    step: element.def.step
                });
                control.state = range.current === undefined ? -Infinity : range.current;
                break;
            case Kind.Text:
                control = new TextControl(this.keyboardHolder, element.name || "");
                control.state = element.def.current;
                break;
            case Kind.Color:
                control = new ColorControl(element.name || "");
                control.state = toHexColor(element.def.current);
                break;
            case Kind.Toggle:
                control = new ToggleButton(new Clock(), element.name || "");
                control.state = element.def.current;
                break;
            case Kind.Ghost:
                parent.skip(1);
                break;
            case Kind.Xy:
                control = new XyControl(element.name || "", element.def.x, element.def.y);
                break;
            case Kind.Action:
                control = new ButtonControl(element.name || "", () => {
                }, element.def.face, this.themeProvider);
                break;
            case Kind.Select:
            case Kind.Gradient:
            case Kind.Progress:
            case Kind.Zoom:
                console.error("kind " + element.kind + " not implemented");
                break;
            default:
                throw new Error("Unexpected kind: " + element.kind);
        }
        if (control) {
            parent.add(control);
            this.interactionHandler.register(control);
            // Some elements do not need data registered (eg. toggle for panels)
            if (addData) {
                parent.userData.values[element.name] = control.state;
                control.addEventListener("stateChanged", (value) => {
                    if (value.state !== parent.userData.values[element.name]) {
                        parent.userData.values[element.name] = value.state;
                        // Dispatch event to root
                        this.panelRoot.dispatchEvent({ type: "stateChanged", state: this.panelRoot.userData.values });
                    }
                });
            }
        }
        return control;
    }
}
