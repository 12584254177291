import UiComponent, { DEFAULT_GEOMETRY } from "../../UiComponent";
import { MeshBasicMaterial } from "three";
export default class TextControl extends UiComponent {
    keyboardHolder;
    text;
    keyboard = undefined;
    constructor(keyboardHolder, description) {
        super("Hello world", description, new MeshBasicMaterial({ color: 0, transparent: true }), DEFAULT_GEOMETRY);
        this.keyboardHolder = keyboardHolder;
        this.text = this.addText(this._state, 0, 0);
        this.intersectedObjects = [this.text, this];
    }
    onSelectStart() {
        this.keyboard = this.keyboardHolder.getKeyboard();
        if (this.keyboard) {
            this.text.color = 0x777777;
            this.keyboard.focus();
            this.keyboard.onchange((value) => {
                this.state = value;
                this.text.color = 0xffffff;
            });
            this.keyboard.onclosed((value) => {
                // This way user does not necessarily erase the text
                if (this.state) {
                    this.state = value;
                }
                this.text.color = 0xffffff;
                this.keyboard = undefined;
            });
        }
    }
    get state() {
        return this._state;
    }
    set state(value) {
        if (value !== this._state) {
            this._state = value;
            this.text.text = value;
            this.dispatchEvent({ type: 'stateChanged', state: value, target: this });
        }
    }
}
