import { ShaderMaterial } from "three";
import UiComponent from "../../UiComponent";
import vertexShader from "./vertex.glsl";
import fragmentShader from "./fragment.glsl";
const shaderMaterial = new ShaderMaterial({
    vertexShader, fragmentShader,
    uniforms: {
        progress: { value: 1.0 }
    },
    transparent: true
});
export default class ToggleButton extends UiComponent {
    clock;
    lastStateChange = -1;
    constructor(clock, description) {
        super(false, description, shaderMaterial.clone());
        this.clock = clock;
    }
    onSelectStart() {
        this.state = !this.state;
    }
    onBeforeRender = (renderer, scene, camera, geometry, material) => {
        if (material instanceof ShaderMaterial) {
            const progress = Math.min(1, (this.clock.getElapsedTime() - this.lastStateChange) * 5);
            material.uniforms.progress.value = this.state ? progress : 1 - progress;
        }
    };
    get state() {
        return this._state;
    }
    set state(value) {
        if (this._state !== value) {
            this._state = value;
            this.lastStateChange = this.clock.getElapsedTime();
            this.dispatchEvent({ type: 'stateChanged', state: value, target: this });
        }
    }
}
